<template>
  <div style="min-height: 400px">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === null">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found in your selected items. Check
        <b-link class="alert-link" to="/apps/products"> Products </b-link>
        for other items.
      </div>
    </b-alert>
    <div v-if="product">
      <div class="mx-1">
        Welcome to Automatum! We have listed all your files for this product.
        You can download them and use them accordingly. Thank you for trusting
        us.
      </div>
      <div class="mb-1 mx-1 mt-1" style="font-size: 16px">
        <div class="mb-1">
          PRODUCT NAME:
          <span style="font-weight: bold">{{ product.name }}</span>
        </div>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          @click="downloadTemplate(product.template_url)"
          variant="primary"
        >
          <div v-if="isLoading" class="loader" style="font-size: 12px">
            Processing...
          </div>
          <div v-else>Download</div>
        </b-button>
      </div>

      <div
        v-if="product.video_url"
        class="d-flex align-items-center justify-content-center mt-2 mb-2"
      >
        <iframe
          :src="product.video_url"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { generateSignedUrl } from "@/aws/Api";
import store from "@/store";
export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    product: function () {
      return store.state["app-products"].selectedProduct;
    },
  },
  methods: {
    async downloadTemplate(event) {
      try {
        this.isLoading = true;
        const url = new URL(event);
        const response = await generateSignedUrl(
          url.pathname.replace(/^\/+/g, "")
        );
        window.open(response, "_blank");
        this.isLoading = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully downloaded your assets!`,
          },
        });
      } catch (error) {
        this.isLoading = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum`,
            icon: "CoffeeIcon",
            variant: "error",
            text: `An error occurred while downloading your assets! ${error}`,
          },
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
 